import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCt8IyOfPeDGZVfpJ2Z7JlTP-EuZ3GZtNA",
  authDomain: "princesshotelsdemo.firebaseapp.com",
  projectId: "princesshotelsdemo",
  storageBucket: "princesshotelsdemo.appspot.com",
  messagingSenderId: "842695888803",
  appId: "1:842695888803:web:89b874c5d946c8da129416"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

//console.log("Firestore instance (db):", db); // Console log the Firestore instance

export { db };