// components/Form/FormActions.jsx
import React from 'react';
import { Button, Box } from '@mui/material';
import UTMViewer from './UTMViewer';
import styles from './FormComponent2.module.css';

const FormActions = ({ handleSubmit, utmUrl, setShortenedUrl, isFormValid }) => {
    return (
        <>
            <Box sx={{ textAlign: "center", mt: 0 }}>
                <Button 
                    variant="contained" 
                    sx={{ backgroundColor: "#FF7846", width: "30%", height: "50px", boxShadow: "none", my: 2 }}
                    onClick={handleSubmit}
                    disabled={!isFormValid}
                >
                    BUILD URL
                </Button>
            </Box>
            
            {/* Apply the utmViewerWrapper class only to this Box that wraps UTMViewer */}
            <Box className={styles.utmViewerWrapper}>
                <UTMViewer utm={utmUrl} onShortenedUrl={setShortenedUrl} />
            </Box>
        </>
    );
};

export default FormActions;
