import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import FormFieldsContainer from './FormFieldsContainer';
import useFormState from './hooks/useFormState';
import useDropdownOptions from './hooks/useDropdownOptions';
import FormFeedback from './FormFeedback';
import FormHeader from './FormHeader';
import FormActions from './FormActions';
import styles from './FormComponent2.module.css';
import KeyboardCapslockIcon from '@mui/icons-material/KeyboardCapslock';
import SpaceBarIcon from '@mui/icons-material/SpaceBar';
import UTMViewer from './UTMViewer';

const FormComponent2 = () => {
  const {
    isLoading,
    layoutConfig,
    fieldValues,
    handleFieldChange,
    handleSubmit, // Destructure handleSubmit from useFormState
    utmUrl,
    openSnackbar,
    snackbarMessage,
    handleCloseSnackbar,
    fieldMetadata, // Destructure fieldMetadata from useFormState
    isFormValid, // Assuming this is part of the state managed by useFormState
    disabledStates, // Destructure disabledStates here
    disabledStatesInitialized, // Add this line to destructure disabledStatesInitialized from useFormState
  } = useFormState();

  const {
    dropdownOptions,
    dropdownDependencies,
    updateOptionsBasedOnDependencies,
  } = useDropdownOptions();

//  console.log('[FormComponent2] Rendering');
//  console.log('[FormComponent2] useFormState values:', { isLoading, layoutConfig, fieldValues, utmUrl, openSnackbar, snackbarMessage, fieldMetadata, isFormValid, disabledStates, disabledStatesInitialized });
//  console.log('[FormComponent2] useDropdownOptions values:', { dropdownOptions, dropdownDependencies });


const handleDropdownChange = (fieldName, newValue) => {
  const labelValue = newValue ? newValue.label : '';
  const additionalText = newValue ? newValue.additionalText || '' : '';

//  console.log(`[FormComponent2] handleDropdownChange invoked for field "${fieldName}" with value:`, labelValue, 'Additional Text:', additionalText);

  // If there is additionalText, it should be appended to the main value
  handleFieldChange(fieldName, labelValue, additionalText);

  // Update dependencies using just the labelValue, ignoring additionalText for dependency logic
  updateOptionsBasedOnDependencies(fieldName, labelValue);
};



  if (isLoading) {
//    console.log('[FormComponent2] Still loading...');
    return <div>Loading...</div>;
  }

//  console.log('[FormComponent2] Preparing to render FormFieldsContainer and other children');

  return (
    <Card className={styles.formComponent} sx={{ width: '90%', margin: '0 auto', mt: 1 }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: '0 10px' }}> {/* Adjust padding as needed */}
      <FormHeader
        title="Google UTM Builder"
        subheader="by Chargebee "
        avatarSrc="/chargebeelogo.png"
        avatarSize={{ width: 135, height: 105 }}
      />
      </div>
      {/* Form settings caption */}
      <Typography variant="caption" sx={{ color: 'gray', textAlign: 'center', mt: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', mb: 2 }}>
        <span style={{ fontWeight: 'bold' }}>Form settings:</span>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <KeyboardCapslockIcon fontSize="inherit" sx={{ color: 'inherit', marginLeft: '4px', marginRight: '4px' }} /> 
          no caps allowed, 
        </span>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <SpaceBarIcon fontSize="inherit" sx={{ color: 'inherit', marginLeft: '4px', marginRight: '4px' }} />
          spaces as "_"
        </span>
      </Typography>
      <CardContent>
        <FormFieldsContainer
          isLoading={isLoading} // Pass isLoading to FormFieldsContainer
          disabledStatesInitialized={disabledStatesInitialized}
          layoutConfig={layoutConfig}
          fieldValues={fieldValues}
          handleFieldChange={handleFieldChange}
          handleDropdownChange={handleDropdownChange}
          dropdownOptions={dropdownOptions}
          dropdownDependencies={dropdownDependencies}
          fieldMetadata={fieldMetadata} // Pass fieldMetadata to FormFieldsContainer
          disabledStates={disabledStates}
        />
        {/* Pass handleSubmit to FormActions */}
      </CardContent>
      <Box>
        <FormActions handleSubmit={handleSubmit} utmUrl={utmUrl} isFormValid={isFormValid} />
      </Box>
      <FormFeedback open={openSnackbar} message={snackbarMessage} onClose={handleCloseSnackbar} />
    </Card>
  );
};

export default FormComponent2;
