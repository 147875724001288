import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4285F4',
    },
    secondary: {
      main: '#42F456',
    },
    background: {
      default: '#f5f5f5', // Light grey background
      paper: '#FFFFFF', // This is usually the background for components like Card, Paper, etc.
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#E6E6FA',  
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#FFFFFF', // Set AppBar background to white
        },
      },
    },
  },
});

export default theme;
